@media (min-width: 768px) {
  #pags_responsividade_margin_tarefas {
    min-width: 1090px;
  }
  #check:not(:checked) ~ #pags_responsividade_margin_tarefas.conteudo_pagina {
    width: 1130px;
  }
}

#pags_responsividade_margin_tarefas {
  padding-right: 33px;
}
