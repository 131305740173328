/*--------- Slide ------------*/
.drop-enter {
  height: 0;
  overflow: hidden;
}

.drop-enter-active {
  height: 24px;
  transition: height 300ms;
}

.drop-exit {
  height: 24px;
}

.drop-exit-active {
  height: 0;
  transition: height 300ms;
}

/*--------- Fade ------------*/
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

/*------------slide ------------*/

.slide-enter {
  margin-left: -300px;
}

.slide-enter-active {
  margin-left: 0px;
  transition: margin-left 1000ms;
}

.slide-exit {
  margin-left: 0px;
}

.slide-exit-active {
  margin-left: -300px;
  transition: margin-left 1000ms;
}
