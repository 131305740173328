* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}

html,
body {
  background-color: #f2f3f8;
  height: 100%;
}

input.form-control,
select.form-select {
  font-weight: 500;
  font-size: 16px;
  color: #515c70;
}

/*-----Scrollbar Consulta-----*/
.table-responsive {
  overflow: auto;
  cursor: pointer;
}

.table-responsive::-webkit-scrollbar {
  height: 8px;
}

.table-responsive::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.table-responsive::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2px;
}

.table-responsive::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*-----Scrollbar Vertical-----*/
.scrollbar-vertical {
  overflow-y: auto;
  overflow-x: visible;
}

.scrollbar-vertical::-webkit-scrollbar {
  width: 2px;
}

.scrollbar-vertical::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scrollbar-vertical::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2px;
}

.scrollbar-vertical::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*-----Ediçoes GERAIS das paginas-----*/

#corpo {
  height: 100%;
  background-color: #f2f3f8;
}

#header {
  z-index: 1;
}

.ajuste_icone_arrow {
  position: relative;
  top: -1px;
}

#pags_responsividade_padding_geral.conteudo_pagina,
#pags_responsividade_margin_geral.conteudo_pagina,
#pags_responsividade_margin_tarefas.conteudo_pagina {
  height: 100%;
  padding-top: 62px;
  padding-left: 91px;
  margin-left: 0;
  transition: 1s;
  transition-property: padding-left;
}

#pags_responsividade_padding_geral.conteudo_pagina {
  padding-right: 33px;
}

#pags_responsividade_margin_geral.conteudo_pagina {
  padding-right: 33px;
}

#check:not(:checked) ~ #pags_responsividade_padding_geral {
  padding-left: 301px;
}

#check:not(:checked) ~ #pags_responsividade_margin_geral {
  margin-left: 200px;
  padding-right: 20px;
}

#check:not(:checked) ~ #pags_responsividade_margin_tarefas {
  margin-left: 200px;
  padding-right: 20px;
}

#pags_responsividade_margin_geral.conteudo_pagina,
#pags_responsividade_margin_tarefas.conteudo_pagina {
  transition: all 1s;
}

#root {
  height: 100%;
}

.row_form,
.row_form_2 {
  position: relative;
  padding: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 9px;
  padding-right: 9px;
  background-color: #fff;
}

.row_form_cinza {
  width: 100%;
}

.col_g {
  --bs-gutter-x: 17px;
  --bs-gutter-y: 0px;
}

input.input_com_span:focus,
select.input_com_span:focus {
  outline: none;
  box-shadow: none;
  border-color: #ced4da;
  background: transparent;
}

.input_icon_arrow {
  background-image: url("../src/assets/Icons/arrow-back-ios-rounded.svg");
  background-repeat: no-repeat;
  background-size: 17px;
  background-position: 18px 7px;
}

.input_icon_arrow_dir {
  background-image: url("../src/assets/Icons/arrow-back-ios-rounded.svg");
  background-repeat: no-repeat;
  background-size: 17px;
  background-position: calc(100% - 7px) 8px;
}

.input_icon_arrow_dir_formListaDeInputs {
  background-image: url("../src/assets/Icons/arrow-back-ios-rounded.svg");
  background-repeat: no-repeat;
  background-size: 13px;
  background-position: calc(100% - 7px) 7px;
}

.input_icon_search {
  background-image: url("../src/assets/Icons/search-rounded.svg");
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 7px 8px;
}

.input_icon_search_dir {
  background-image: url("../src/assets/Icons/search-rounded.svg");
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: calc(100% - 4px) 8px;
}

div.input_icon_date {
  background-image: url("../src/assets/Icons/calendar-today-outline.svg");
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: 10px center;
}

div.input_icon_date input:disabled {
  background-color: transparent;
}

.form_select_styled option[disabled] {
  display: none;
}

.div_checks {
  background-color: #f7f7f7;
  border-radius: 5px;
  padding: 5px 0 0 14px;
  border: 2px solid #e8e8e8;
}

.col_checks {
  background-color: #f7f7f7;
  padding: 0 0 4px;
}

.row_permissoes {
  padding: 20px;
}

.row_form_2 {
  border-radius: 0 0 5px 5px;
}

.margin_right {
  margin-right: 10px;
}

.header_body_conteudo {
  background-color: #fff;
  border-radius: 5px 5px 0 0;
  justify-content: space-between;
}

.header_body_conteudo span {
  font-size: 0.9em;
  font-weight: 600;
  color: #515c70;
}

.header_body_conteudo svg {
  color: #515c70;
}

.titulo_conteudo {
  align-items: center;
  height: 62px;
  margin-left: -11px;
}

.container_conteudo {
  padding-top: 55px;
}

.cor_letra_primaria {
  color: #515c70;
}

.cor_letra_secundaria {
  color: #9399a4;
}

.header_conteudo button {
  margin-right: 10px;
}

.header_conteudo {
  display: flex;
  align-items: center;
  height: 40px;
  margin-right: -22px;
}

.header_body_nav {
  margin-right: 2px;
}

.cor_botao_adicionar {
  background: #515c70;
}

.space_between {
  justify-content: space-between;
}

#check:checked ~ #header #div_logo_header {
  min-width: 60px;
}

#check:not(:checked) ~ #header #div_logo_header {
  min-width: 270px;
}

#header #div_logo_header {
  transition: all 1s;
}

@media (min-width: 768px) {
  input.form_control_col_8_style {
    width: 101.1%;
  }

  select.form_select_col_2_style {
    width: 104.5%;
  }
}

/*-------animacoes-------*/

/*-------Modais-------*/
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.modal-overlay {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  /*backdrop-filter: blur(1px);*/
}

/*----Carousel-------*/

a.carousel-control-prev,
a.carousel-control-next {
  margin-left: -4px;
  margin-right: -4px;
  /* Adicione mais estilos conforme necessário */
  width: 40px; /* Defina o tamanho de largura desejado */
  height: 40px; /* Defina o tamanho de altura desejado */
  fill: red;
}

span.carousel-control-prev-icon,
span.carousel-control-next-icon {
  margin-top: 234px;
}

span.carousel-control-prev-icon {
  background-image: url("https://api.iconify.design/material-symbols/arrow-back-ios-rounded.svg?color=%23515c70");
}

span.carousel-control-next-icon {
  background-image: url("https://api.iconify.design/material-symbols/arrow-back-ios-rounded.svg?color=%23515c70&rotate=180deg");
}

/*--------print---------*/

@media print {
  .hide_on_print {
    display: none;
  }

  html,
  body {
    background-color: #fff;
  }

  #corpo {
    background-color: #fff;
  }
}

/* tables */

.tr-important {
  border-radius: 8px !important;
}
.td-important {
  border: none;
}
