#check:checked ~ #side {
  margin-left: -210px;
}

#check:checked ~ #side a span {
  display: none;
}

#check:checked ~ #side a {
  margin-left: 210px;
  width: auto;
}

#check:checked ~ #side button {
  margin-left: 210px;
  width: 58px;
  color: #8b8c900d;
}

#check:checked ~ #side button span {
  display: none;
}

#check:checked ~ #side #cadastro_sub {
  display: none;
}

#check:checked ~ #side h5 {
  display: none;
}

#check:checked ~ #side a svg {
  height: 27px;
}

.ajuste_icone {
  padding-right: 5px;
}

#check:checked ~ #header #logo_expanded {
  display: none;
}

#check:checked ~ #header #div_logo_header {
  width: 60px;
}

#div_logo_header {
  transition: all 1s;
}

#check:checked ~ #header #logo_minimalized {
  display: block;
}

#check:checked ~ #side #subtitulo_side {
  margin-left: 208px;
  width: 60px;
  border-radius: 0px 0px 8px 8px;
}

#check:checked ~ #side #subtitulo_side span {
  display: none;
}

#check:checked ~ #side #subtitulo_side svg {
  margin-left: 7px;
  width: 70px;
}

#check:checked ~ #side #nav_secondary_items .nav_impressos {
  border-bottom: 2px solid #d9d9d9;
  border-top: 2px solid #d9d9d9;
}

#cadastro_sub .sub_link {
  color: #515c70;
  font-size: 14px;
}

#cadastro_sub .sub_link.active {
  background-color: transparent;
}

#cadastro_sub {
  list-style: none;
  transform: translateX(-32px);
}

#cadastro_sub .sub_link:hover {
  background-color: transparent;
  text-decoration: none;
  font-weight: 600;
}

#cadastro_submenu a {
  font-size: 14px;
}

#cadastro_submenu a:hover {
  background-color: transparent;
  color: #515c70;
  text-decoration: none;
}

#cadastro_submenu a.active {
  background-color: transparent;
  color: #515c70;
  font-weight: 600;
}
