.span_endereco {
  color: #515c70;
  font-weight: 600;
  border-bottom: 2px solid #515c70;
  line-height: 35px;
  font-size: 0.8em;
  margin-left: 20px;
}

.span_endereco_titulo {
  margin-left: 24px;
}

/*Estilizações dos spans dentro dos inputs*/

.span_input_codigo {
  top: -30px;
  left: 15px;
}

.span_input_telefone {
  top: -30px;
  left: 15px;
}

.span_input_celular {
  top: -30px;
  left: 15px;
}

.span_input_complemento {
  top: -30px;
  left: 15px;
}

.span_input_cpf {
  top: 23px;
  left: 215px;
}

.span_input_email {
  top: -30px;
  left: 15px;
}

.span_input {
  position: relative;
  color: #515c70;
  transition: 0.3s;
}

/*Fim das estilizações dos spans dentro dos inputs*/

.icon-simplify {
  margin-top: 3px;
  margin-right: 18px;
  font-size: 2.2em;
  color: #515c70;
  cursor: pointer;
}

.span_label_codigo {
  margin-left: 6px;
}

label.label_codigo {
  padding: 6px;
}

.icon-chart {
  margin-bottom: 2px;
}

.row_endereco_aba {
  background-color: white;
  border-radius: 0 0 5px 5px;
}

.row_endereco_conteudo {
  margin-top: 12px;
  background-color: white;
  border-radius: 5px 5px 0 0;
  color: #515c70;
}

.row_endereco_conteudo span {
  font-weight: 600;
  font-size: 0.95em;
}

#codigo::placeholder {
  position: relative;
}

.shake-animation {
  animation: shake 0.5s;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}
