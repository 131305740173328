@media (min-width: 768px) {
  #pags_responsividade_margin_geral.conteudo_pagina {
    min-width: 1130px;
  }
  #check:not(:checked) ~ #pags_responsividade_margin_geral.conteudo_pagina {
    width: 1130px;
  }
}

/* @media (max-width: 575.98px) {
  #pags_responsividade_margin_geral {
    min-width: 551px;
  }
} */
