.sidebar-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 250px; /* ou outro valor desejado */
  background: rgba(250, 250, 250, 0.95); /* ou outra cor desejada */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 10px 10px;
  margin-top: 55px;
  z-index: 1;
}
